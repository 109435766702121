<template>
    <div :class="data.Class" :style="data.Style">
        <div v-for="(item, n) in stringReader(data.DataSource)" :key="n">
            <div :class="data.Content.Title.Class" v-html="stringReader(data.Content.Title.Value, item)"></div>
            <div class="flex flex-wrap w-full" :class="data.Content.Tag.ContainerClass">
                <div class="mr-3" :class="data.Content.Tag.Class" @click="mainAction(data.Content.Tag.OnClick, tag)" v-ripple v-for="(tag, indexTag) in item.Data" :key="indexTag">
                    {{ stringReader(data.Content.Tag.Label, tag) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    }
}
</script>
